import C from 'consistencss';
import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import {bgColor, bordColor, dColors, palette, textColor} from '../styles/color';
import {isSmall} from '../styles/sizes';
import {wFonts} from '../styles/typografy';
import {pillWrapper} from '../styles/ui';
import {DgIcon} from '../⚛️ atoms/DgIcon';

export default ({
  name,
  iconName,
  onPress,
  children,
  transp,
  number,
  selected,
  compact = isSmall,
  color = dColors.primary,
}) => {
  const stateColor = selected
    ? transp
      ? palette.white
      : color
    : palette.gray300 + '80';
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        pillWrapper,
        C.radius8,
        C.p2,
        bgColor(selected && palette.whiteTransp),
        /*: C.opacity40*/ bordColor(stateColor),
      ]}>
      {(name || iconName) && (
        <DgIcon color={stateColor} name={name} iconName={iconName} />
      )}
      {!compact && name && (
        <Text style={[C.px1, textColor(stateColor), wFonts.title2]}>
          {name}
        </Text>
      )}
      {!compact && number && (
        <Text style={[C.px1, textColor(palette.gray500), wFonts.subtitle]}>
          {number}
        </Text>
      )}
      {children}
    </TouchableOpacity>
  );
};
