import C from 'consistencss';
import React, {useState} from 'react';
import {Text, View} from 'react-native';
import {useStores} from '../store/UserProvider';
import {dColors, palette, textColor} from '../styles/color';
import {wFonts} from '../styles/typografy';
import CircularSelector from '../🧫 molecules/CircularSelector';
import {sizeIcons, WfAvatar} from '../🧫 molecules/WfAvatar';
import PaletteSelector from '../🧬organisms/PaletteSelector';

export default () => {
  const {user, colors, social, mainGrad} = useStores();
  const [showColors, toggleColors] = useState(false);
  return (
    <View style={[C.itemsCenter, C.pt8]}>
      {/*<View style={[C.itemsCenter, C.row, C.pt8]}>*/}
      <CircularSelector list={Object.values(social)} links={user.social} />
      <View style={[C.row, C.justifyCenter]}>
        <WfAvatar
          size={sizeIcons.xl}
          showBubble={false}
          onPress={() => toggleColors(!showColors)}
          color={dColors.primary}
          image={user.image}
          name={user.name}
        />

        {/* <QRCode
          value={user.social.web}
          logo={user.image}
          logoBorderRadius={40}
          logoMargin={30}
          size={160}
          //quietZone={40}
          backgroundColor={palette.whiteTransp}
          color={palette.blue}
          enableLinearGradient
          linearGradient={mainGrad}
          logoSize={50}
          logoBackgroundColor="transparent"
        />*/}
      </View>
      {/*</View>*/}

      {/*<Pressable
        style={[
          C.bgWhite,
          C.p4,
          C.radius4,
          isSmall ? [C.mt16] : [C.absolute, C.left90, C.top_6],
        ]}>
        <Image
          source={{uri: 'https://imgur.com/ZwKUWkE.png'}}
          style={[isSmall ? vSizes.cardSm : vSizes.cardMd]}
        />
      </Pressable>*/}
      {/*</View>*/}
      <Text style={[wFonts.heroTitle, C.mt14, textColor(palette.blueDark2)]}>
        {user.name}
      </Text>
      <Text style={[wFonts.highlight, C.mb6, textColor(dColors.primary)]}>
        {user.headline}
      </Text>

      {/**Color selector*/}
      {showColors && (
        <View style={[C.itemsCenter]}>
          <PaletteSelector />
        </View>
      )}
    </View>
  );
};
