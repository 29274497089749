import chroma from 'chroma-js';
import {boxShadow} from 'consistencss';
import {BASE_PIXEL} from './sizes';

export const wColors = {
  primary: '#3B6A8F',
  primaryLight: '#7DA4C4',
  secondary: '#8A3B8F',
  third: '#8F603B',
};

/** https://coolors.co/palette/8ecae6-219ebc-023047-ffb703-fb8500*/
export const paletteOrBlue = /* Array */ [
  '#8ecae6', //PrimBg
  '#219ebc', //PrimLight
  '#023047', //Prim
  '#ffb703', //SecLight
  '#fb8500', //SecBg
];
export const paletteOralify = {
  yellowPrim: '#b29f25',
  turqPrim: '#04b581', // Header 2
  bluePrim: '#0290c9',
  blueDark: '#006699', // darkBlue
  greenPrim: '#6ebe47', //primary apple
  redSec: '#ec4d60', // aumentar la saturación y luminosidad
  orangeSec: '#f89944', // aumentar la saturación y luminosidad
  blueSec: '#66cceb',
  purpleSec: '#6c5fc7', // disminuir la saturación y luminosidad

  secondaryLightGrey: '#f3f7f7',
  secondaryDarkGrey: '#768389',

  greyDark: '#43525B',
  shadow: '#0000003F', // opacity TODO Be removed
  primaryMercuryGrey: '#e3e3e3', // For dividers
  white: '#fff',
  lightGrey: '#f6f6f6',
  blueLight: '#68A8D9', // TODO Deprecate?
  orangeLight: '#f8ad70',
  background: '#f7f7f7', // 30% - primaryMercuryGrey: "#e3e3e3",
  transp: '#f7f7f740', // 30% - primaryMercuryGrey: "#e3e3e3",
  secondaryGrey: '#E1EEF0',
  greenSec: '#91d18b', // complementario del SecondaryRed
  yellowSec: '#bda834', // complementario del SecondaryPurple
  //Greyish:
  whiteTwo: '#fafafa',

  whiteThree: '#e7e7e7',
  whiteFour: '#e5e5e5',
  greyish: '#b6b6b6',
  border: '#b6b6b640', // 40% - background: "#f7f7f7",

  black: '#000000',
  softPink: '#faa6ae',
  salmon: '#F28372',
  turqSec: '#32c6a2',
};

export const orangeGrad = [
  paletteOralify.purpleSec,
  paletteOralify.redSec,
  paletteOralify.orangeSec,
  paletteOralify.orangeLight,
];

export const customPalettes = {
  oralify: {
    primary: paletteOralify.orangeSec,
    primaryLight: paletteOralify.orangeLight,
    secondary: paletteOralify.purpleSec,
    secondaryLight: paletteOralify.orangeLight,
    highlight: paletteOralify.redSec,
  },
  psicologia: {
    primary: '#163029',
    primaryLight: '#fafafa',
    secondary: '#7d9c91',
    /*secondaryLight: '#32c6a2',*/
    highlight: '#d0c781',
  },
  dgFolio: {
    primary: '#6377e0',
    primaryLight: '#fafafa',
    secondary: '#33e8c7',
    secondaryLight: '#32c6a2',
  },
  dp: {
    primary: '#3D83DF',
    primaryLight: '#eef4fd',
    primaryDark: '#1662C6',
    secondary: '#00C3A5',
  },
  lowKey: {
    primaryLight: '#b66fe0',
    primary: '#931f74',
    primaryDark: '#60276e',
    secondaryLight: '#3a5d92',
    secondary: '#0a0425',
  },
  dgFolioOrange: {
    primaryLight: '#ffb703',
    primary: '#fb8500',
    secondaryLight: '#8ecae6',
    secondary: '#219ebc',
    primaryDark: '#023047',
  },
  dgInverse: {
    primaryLight: '#8ecae6',
    primary: '#219ebc',
    primaryDark: '#023047',
    secondaryLight: '#ffb703',
    secondary: '#fb8500',
  },
};

export const defPaletteKey = Object.keys(customPalettes)[3];
export const defPalette = customPalettes.dgFolio;

/* Object */
export const customGradObj = {
  primaryLight: '#8ecae6',
  primary: '#219ebc',
  primaryDark: '#023047',
  secondaryLight: '#ffb703',
  secondary: '#fb8500',
};
export const currPalette = customGradObj;

/** @deprecated*/
export const vColors = {
  primaryBlue: '#6377e0',
  primaryGreen: '#33e8c7',
  white: '#ffffff',
  whiteTwo: '#fafafa',
  whiteFive: '#f5f5f5',
  whiteThree: '#e7e7e7',
  whiteFour: '#e5e5e5',
  greyish: '#b6b6b6',
  greyishBrown: '#585858',
  dark: 'rgb(33, 37, 41)',
  black: '#000000',
  softPink: '#faa6ae',
  salmon: '#F28372',
  greenishTeal: '#32c6a2',
};
export const dColors = {
  dark: '#00203F', // Azul Marino Oscuro
  light: '#ADEFD1', // Verde Agua Claro
  white: '#fff', // Azul Vivo
  primary: '#095680', // Azul Vivo
  whatsapp: '#25D366', // Verde
  gray400: '#BDBDBD', // Gris
  secondary: '#22A0C6', // Turquesa
  error: '#FF0000', // Rojo
};

export const palette = {
  // Main
  blue: '#3D83DF',
  blueLight: '#eef4fd',
  blueDark: '#1662C6',
  turquoise: '#00C3A5',
  turquoiseLight: currPalette.secondaryLight,
  turquoiseDark: '#00b39b',
  secondaryLight: '#ffb703',
  secondary: '#fb8500',
  purple: '#8870d5',
  purpleLight: '#f3f0fa',
  yellow: '#ffc600',
  yellowLight: '#fefbdc',
  yellowDark: '#997800',
  red: '#e74c3c',
  redLight: '#fdf2f1',
  redDark: '#b42516',
  white: '#ffffff',
  whiteTransp: '#ffffff20',
  gray100: '#f7f9fa',
  gray200: '#edeff2',
  gray300: '#dcdfe3',
  gray500: '#98a1ab',
  gray600: '#627282',
  gray900: '#1b2734',
  dark: '#1b2734',
  blueDark2: '#00203F',
  black: '#000',

  //Opacity:
  darkOpacity: '#00000004',

  //Custom
  gold: '#FFD700',
  paleGrey: '#f6f8fc',
  paleGreyTwo: '#f8efdd',
  paleGreyThree: '#dee0e6',
};

export const OPACITY = '40';

export const bgColor = (color = palette.paleGrey) => ({backgroundColor: color});
export const bordColor = (color = palette.gray900, width = 1) => ({
  borderColor: color,
  borderWidth: width,
});
export const textColor = (color = palette.gray900) => ({color: color});
export const tintColor = color => ({tintColor: color});

/**Gradients*/
export const gradGold = chroma.scale([
  palette.yellow,
  palette.yellowLight,
  palette.yellowDark,
  palette.gold,
]);
export const gradSteps = (
  num = 6,
  colors = [currPalette.primary, currPalette.secondary],
) => chroma.scale(colors).mode('lch').colors(num);
export const gradFull = chroma.scale(Object.values(palette));
export const grad = chroma.scale([
  palette.blueDark,
  palette.blue,
  palette.blueLight,
  'red',
]);

export const gradBgColor = palette.gold + '60';

/**Shadows*/
export const shadowCol = (col = palette.blue, blur = 5, opacity = 1) => ({
  shadowOpacity: 0.4,
  shadowColor: col,
  shadowRadius: BASE_PIXEL * 3,
  /*elevation: 12,*/
  shadowOffset: {width: 2, height: 2},
});
/*boxShadow(12, 0, 10, 24, vColors.primaryBlue, 0.12);*/

//boxShadow(getSizeFor(3), 0, getSizeFor(2), getSizeFor(6), col, 0.12);

/** @deprecated*/
export const vShadow = {
  highlightSel: shadowCol(),
  highlight: boxShadow(12, 0, 10, 24, vColors.greyish, 0.2),
  regular: boxShadow(12, 0, 10, 10, vColors.whiteThree, 0.2),
};
