import C from 'consistencss';
import {useObserver} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {FlatList, Text, View} from 'react-native';
import {Timeline} from '../\uD83E\uDDECorganisms/Timeline';

import ProfileHeader from '../comp/ProfileHeader';
import {Wrapper} from '../comp/Wrapper';
import {useStores} from '../store/UserProvider';
import {dColors, palette, textColor} from '../styles/color';
import {isSmall} from '../styles/sizes';
import {wFonts} from '../styles/typografy';
import Pill from '../🧫 molecules/Pill';
import {Header} from '../🧬organisms/Header';

export const Profile = () => {
  const store = useStores();
  const [showFilter, setShowFilters] = useState(false);
  const numColumns = isSmall ? 2 : 4;
  const nDisplay = numColumns * 2 - 1;
  const filtersToShow = showFilter
    ? store.filterDurations
    : store.filterDurations.slice(0, nDisplay);
  const missing = store.matchingSkills.length - filtersToShow.length;
  const expandIcon = {
    iconName: showFilter ? 'chevron-up' : 'chevron-down',
    name: !showFilter ? missing : filtersToShow.length,
  };

  useEffect(() => {
    const fetchData = async () => {
      await store.initDB();
    };
    fetchData().catch(console.error);
  }, [store]);
  return useObserver(() => (
    <>
      <Header />
      <Wrapper
        /*bgGradient={store.mainGrad}*/
        HeaderChild={
          <View
            style={[
              C.mlQuarter,
              C.itemsCenter,
              C.selfCenter,
              C.maxwFull,
              C.wFull,
              C.top_2,
              isSmall ? [C.left_10, C.top4] : C.top2,
              /*C.mb_16,*/
              C.justifyCenter,
            ]}>
            <ProfileHeader />
            {/* <View
              style={[
                C.flex,
                isSmall
                  ? [
                      C.ml6,
                      C.top_14,
                      {transform: [{scaleX: 0.7}, {scaleY: 0.7}]},
                    ]
                  : C.ml10,
                C.selfCenter,
              ]}>
              <iframe
                height={500}
                width={600}
                frameBorder={0}
                style={{backgroundColor: '#fff'}}
                src={
                  'https://oralify.com/embed/guillermoS?transparent=1' +
                  (isSmall ? '/qr' : '')
                }
              />
            </View>*/}
            {/**Oralify*/}

            {/*<View style={[!isSmall && C.row, {gap: 20}]}>
              <VButton
                color={[palette.purple, palette.red]}
                highlight
                title={'Ask me anything - 5€'}
                onPress={openWeb}
                icon={'mic'}
              />
              <VButton
                color={[palette.red, palette.purple]}
                title={'Web landing - 299€'}
                onPress={openWeb}
                icon={'desktop'}
              />
            </View>*/}
            {store.filterDurations.length > 0 && (
              <FlatList
                data={filtersToShow}
                numColumns={numColumns}
                style={[C.my4 /*, {width: '90%'}*/]}
                extraData={store.matchingSkills}
                contentContainerStyle={[C.itemsCenter, C.justifyCenter]}
                ListFooterComponent={() => (
                  <Pill
                    iconName={expandIcon.iconName}
                    name={expandIcon.name}
                    number={!showFilter ? 'more' : ''}
                    selected
                    color={palette.gray600}
                    onPress={() => setShowFilters(!showFilter)}
                  />
                )}
                keyExtractor={({name}, index) => name + '-' + index}
                renderItem={({item, index}) => {
                  const {key, name, marked, duration, formattedDuration} = item;
                  const isLast = index === filtersToShow.length - 1;
                  const onToggle = () =>
                    isLast
                      ? setShowFilters(!showFilter)
                      : store.toggleCurrFilter(key);
                  return (
                    duration > 0 && (
                      <Pill
                        /* transp*/
                        {...item}
                        onPress={onToggle}
                        compact={false}
                        /*highlight*/
                        selected /*={store.hasMatchingFilters ? !!marked : true}*/
                        number={!isLast ? formattedDuration : ''}
                        style={{margin: 5}}
                      />
                    )
                  );
                }}
              />
            )}

            <Text
              style={[
                wFonts.body1,
                C.mb6,
                textColor(dColors.primary),
                {textAlign: 'center'},
              ]}>
              <Text
                style={[wFonts.subtitle, C.mb6, textColor(palette.gray600)]}>
                Total Exp: {'  '}
              </Text>
              {store.totalDuration} (
              {new Intl.NumberFormat('es-ES', {
                maximumSignificantDigits: 2,
              }).format(store.totalWorkedHours)}
              {' h)'}
            </Text>
          </View>
        }>
        {/*<ScrollView contentContainerStyle={[C.p20]}>*/}
        <Timeline />
        {/*</ScrollView>*/}
      </Wrapper>
    </>
  ));
};
