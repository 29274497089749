/*
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
*/
import React, {useRef} from 'react';
/*import {profile} from './App';*/
import {vImgs} from './styles/icons';
import {AppsofactoLanding} from './screens/AppsofactoLanding';
import {WfText} from './⚛️ atoms/WfText';
import {textTypes} from './styles/typografy';
/*import {isWeb, vColors, vImgs} from './design/vStyles';
import {AddVet} from './screens/AddVet';
import {ChatList} from './screens/ChatList';
import {Home} from './screens/Home';
import {Landing} from './screens/Landing';
import {Onboarding} from './screens/Onboarding';
import {Reminder} from './screens/Reminder';
import {VetLanding} from './screens/VetLanding';
import {VetList} from './screens/VetList';*/
import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import C from 'consistencss';
import {Image} from 'react-native';
import {Profile} from './screens/Profile';
import {DgFolio} from './screens/PortfolioLanding';
import {Helmet} from 'react-helmet';

const linkingOptions = {
  prefixes: [
    'https://dgfolio.com',
    'https://www.dgfolio.com',
    'dgfolio://',
    'https://dgfolio.web.app/',
    'dgfolio.firebaseapp.com',
  ],
  config: {
    screens: {
      HomeStack: {
        path: 'stack',
        initialRouteName: 'Home',
        screens: {
          Home: 'home',
          NotFound: '*',
          Profile: {
            path: 'user/:id/:age',
            parse: {
              id: id => `there, ${id}`,
              age: Number,
            },
            stringify: {
              id: id => id.replace('there, ', ''),
            },
          },
        },
      },
      Settings: 'settings',
    },
  },
};
export const signedIn = {
  VetLanding: {
    icon: vImgs.home,
    Comp: AppsofactoLanding,
    name: 'Veterinaries',
    title: 'Para profesionales',
  },
  Landing: {
    icon: vImgs.home,
    Comp: DgFolio,
    name: 'portfolio',
    title: 'Inicio',
  },
  GuilleFolio: {
    icon: vImgs.home,
    Comp: Profile,
    name: 'gsierra',
    title: 'Guillermo Sierra - Mobile developer',
  },

  Home: {icon: vImgs.home, Comp: AppsofactoLanding, name: 'Home'},
  /*VetList: {icon: vImgs.home, /!*Comp: VetList,*!/ name: 'VetList'},
  Chat: {icon: vImgs.chat, /!*Comp: ChatList,*!/ name: 'Chat'},
  Reminder: {icon: vImgs.bell, /!*Comp: Reminder,*!/ name: 'Recordatorios'},*/
  Profile: {
    icon: vImgs.profile,
    Comp: Profile,
    name: 'Perfil',
    title: 'Iniciar Sesión',
  },
};

export const signedOut = {
  Splash: {icon: vImgs.profile, /*Comp: Reminder,*/ name: 'Splash'},
};
export const headerNav = [
  signedIn.Landing,
  signedIn.VetLanding,
  /*signedIn.Profile,*/
];

const linking = {
  prefixes: [
    /* your linking prefixes */
    ['https://planetmoji.com', 'https://www.planetmoji.com', 'planetmoji://'],
  ],
  config: {
    /* configuration for matching screens with paths */
    screens: {
      Home: 'Home',
      Chat: 'Chat',
      Reminder: 'Reminder',
      Profile: 'Perfil',
      NotFound: '*',
    },
  },
};

/*const Tab = createBottomTabNavigator();*/

/*export const safeBack = ({canGoBack, goBack, navigate}) =>
  canGoBack()
    ? goBack()
    : navigate(isWeb ? screens.Onboarding.name : screens.Welcome.name);*/
/*const Stack = createStackNavigator();*/
const Stack = createNativeStackNavigator();

const screensMap = Object.values(signedIn).map(
  ({name, icon, badge, Comp}, index) => (
    <Stack.Screen
      key={name}
      name={name}
      icon={icon}
      options={{
        title: '',
        tabBarIcon: ({color, size}) => (
          <Image
            source={icon}
            name={name}
            style={[C.w6, C.h6, {tintColor: color}]}
          />
        ),
      }}
      component={Comp}
    />
  ),
);

export const DgNavigator = () => {
  const navigationRef = useRef();
  const routeNameRef = useRef();
  return (
    <NavigationContainer
      documentTitle={'Guillermo Sierra'}
      /*linking={linkingOptions}*/
      /*ref={navigationRef}
      onReady={() =>
        (routeNameRef.current = navigationRef.current.getCurrentRoute().name)
      }*/
      fallback={<WfText type={textTypes.bodyBold}>Loading...</WfText>}
      /*onStateChange={async () => {
        /!*todo add analytics
           const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current.getCurrentRoute().name;

        if (previousRouteName !== currentRouteName) {
          await gAnalytics.logEvent('screen_' + currentRouteName, {
            screen_name: currentRouteName,
            screen_class: currentRouteName,
          });
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;*!/
      }}*/
    >
      <Helmet>
        <title>
          Guillermo Sierra - DgFolio - Create your personal digital Portfolio
        </title>
        <meta
          name="description"
          content={
            'Conoce a Guille, experto en en Oralify. Habla coy resuelve tus problemas cotidianos.'
          }
        />
      </Helmet>
      <Stack.Navigator
        /*initialRouteName={signedIn.GuilleFolio.name}*/
        screenOptions={{headerShown: false}}>
        <Stack.Screen
          key={'profile'}
          name={'Guillermo Sierra - dgFolio - Create your personal Portfolio'}
          icon={'icon'}
          /*options={{
            title: '',
            tabBarIcon: ({color, size}) => (
              <Image
                source={icon}
                name={name}
                style={[C.w6, C.h6, {tintColor: color}]}
              />
            ),
          }}*/
          component={Profile}
        />
        {/*{screensMap}*/}
      </Stack.Navigator>
    </NavigationContainer>
  );
};
