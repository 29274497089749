import {useNavigation} from '@react-navigation/core';
import C from 'consistencss';
import {useObserver} from 'mobx-react';
import React, {useState} from 'react';
import {FlatList, Image, Text, TouchableOpacity, View} from 'react-native';
import {signedIn} from '../routes';
import {mockUsers} from '../store/AuthStore';
import {useStores} from '../store/UserProvider';
import {bordColor, palette, textColor} from '../styles/color';
import {vImgs} from '../styles/icons';
import {isSmall, percWidth, vSizes} from '../styles/sizes';
import {wFonts} from '../styles/typografy';
import {pillWrapper} from '../styles/ui';
import {WfAvatar} from '../🧫 molecules/WfAvatar';
import PaletteSelector from './PaletteSelector';

export const Header = ({users = mockUsers}) => {
  const store = useStores();
  const {navigate} = useNavigation();
  const [showPalette, setShowPalette] = useState();

  return useObserver(() => (
    <View
      style={[
        C.row,
        {backgroundColor: palette.white},
        C.pt4,
        C.justifyCenter,
        isSmall ? percWidth() : C.minwFull,
        C.top0,
      ]}>
      <View style={[C.justifyCenter, C.flex]}>
        <View
          style={[C.row, C.flex, C.justifyBetween, isSmall ? C.px1 : C.px30]}>
          {/* Logo */}
          <TouchableOpacity
            style={[C.row, C.itemsCenter]}
            onPress={() => setShowPalette(!showPalette)}>
            <Image
              source={vImgs.logoWhite}
              resizeMode={'contain'}
              style={[vSizes.xl, C.mr1]}
            />
            <Text
              style={[
                wFonts.mainTitle,
                textColor(palette.blueDark2),
                C.font15,
              ]}>
              dgfolio
            </Text>
          </TouchableOpacity>

          <View style={[C.row, C.itemsCenter]}>
            {/*<DgIcon
            name={'colours'}
            size={10}
            color={palette.white}
            extraStyle={[C.ml4]}
          />*/}
            <FlatList
              data={users}
              horizontal
              keyExtractor={({name}, index) => name + '-' + index}
              renderItem={({item, index}) => (
                <TouchableOpacity
                  onPress={() => {
                    navigate(signedIn.Profile.name);
                    store.setUser(item);
                  }}
                  style={[
                    pillWrapper,
                    C.radius20,
                    C.p1,
                    index > 0 && isSmall && C.ml_8,
                    store.user.name === item.name &&
                      bordColor(palette.blueDark2, 2),
                  ]}>
                  <WfAvatar
                    onPress={() => store.setUser(item)}
                    image={item.image}
                    name={item.name}
                    color={store.colors.primary}
                  />
                  {!isSmall && (
                    <Text
                      style={[
                        C.p2,
                        wFonts.textBold,
                        textColor(
                          store.user.name !== item.name
                            ? palette.gray900
                            : palette.blueDark2,
                        ),
                      ]}>
                      {item.name}
                    </Text>
                  )}
                </TouchableOpacity>
              )}
            />
          </View>
        </View>
        {showPalette && <PaletteSelector />}
      </View>
    </View>
  ));
};
