export const vImgs = {
  logo: {uri: 'https://dgfolio.com/assets/logo-bg.png'},
  logoWhite: {uri: 'https://imgur.com/x0S6Ery.png'}, //'https://dgfolio.com/assets/logo.png'},
  woman: {uri: 'https://imgur.com/AO9KiTx.png'},
  pin: {uri: 'https://imgur.com/YFyLGnu.png'},
  online: {uri: 'https://imgur.com/zlqgtVm.png'},
  home: {uri: 'https://imgur.com/ygYYTIm.png'},
  plus: {uri: 'https://imgur.com/kdo6dEv.png'},
  lupa: {uri: 'https://imgur.com/rXGEoLN.png'},
  heartEmpty: {uri: 'https://imgur.com/qp8Ghsm.png'},
  heartFull: {uri: 'https://imgur.com/9jx8ez4.png'},
  filters: {uri: 'https://imgur.com/wRJrHTv.png'},
  apple: {uri: 'https://imgur.com/hMfGr2o.png'},
  facebook: {uri: 'https://imgur.com/N9DKc8s.png'},
  google: {uri: 'https://imgur.com/E78QIV5.png'},
  email: {uri: 'https://imgur.com/URvCjeV.png'},
  camera: {uri: 'https://imgur.com/FpOEvl4.png'},
  calendar: {uri: 'https://imgur.com/C6a13n8.png'},
  dollar: {uri: 'https://imgur.com/3xzijze.png'},
  animalPhotos: {uri: 'https://imgur.com/YHEAwPN.png'},
  clinicVisit: {uri: 'https://imgur.com/G0APlxA.png'},
  onlineVisit: {uri: 'https://imgur.com/SBE1dT4.png'},
  homeVisit: {uri: 'https://imgur.com/j46ETM4.png'},
  tickVerification: {uri: 'https://imgur.com/Pm9sSIJ.png'},
  vetsRounds: {uri: 'https://imgur.com/qM0fQMI.png'},
  vetMan: {uri: 'https://imgur.com/5v3mi3l.png'},
  vetManGroupedDesktop: {uri: 'https://imgur.com/SvmMrs0.png'},
  vetManGroupedMobile: {uri: 'https://imgur.com/OeRNxyO.png'},
  bubbles: {uri: 'https://imgur.com/hVNDYXD.png'},
  face1: {uri: 'https://imgur.com/yP0xYLL.png'},
  face2: {uri: 'https://imgur.com/EHEWmaY.png'},
  face3: {uri: 'https://imgur.com/cWoQkNU.png'},
  face4: {uri: 'https://imgur.com/jFpItUQ.png'},
  face5: {uri: 'https://imgur.com/B2uVx2C.png'},
  SchedulePurple: {uri: 'https://imgur.com/spKcG5w.png'},
  upperFile: {uri: 'https://imgur.com/lorT04z.png'},
  middleFile: {uri: 'https://imgur.com/eoBWOns.png'},
  aboveFile: {uri: 'https://https://imgur.com/1l8E81H.png'},
  leftRightImage: {uri: 'https://imgur.com/NlOfsY7.png'},
  rightLeftImage: {uri: 'https://imgur.com/vUKQFHF.png'},
  mobileMock: {uri: 'https://imgur.com/NKt8pG9.png'},
  greenTick: {uri: 'https://imgur.com/Scm9rqi.png'},
  greenInstagram: {uri: 'https://imgur.com/Nf1pX8X.png'},
  greenFacebook: {uri: 'https://imgur.com/b3p46EG.png'},
  greenLinkedIn: {uri: 'https://imgur.com/9qCuLfL.png'},
  chicaVetPerro: {uri: 'https://imgur.com/d7LvhfJ.png'},
  avatarPlus: {uri: 'https://imgur.com/0LmuS5f.png'},
  medal: {uri: 'https://imgur.com/l1eew00.png'},
  clock: {uri: 'https://imgur.com/MAYBLh2.png'},
  calendarAndFile: {uri: 'https://imgur.com/2UUZ4gU.png'},
  clientsOpinions: {uri: 'https://imgur.com/a8deutk.png'},
  girlDogCameraOnline: {uri: 'https://imgur.com/XBCJbO0.png'},
  profileAriadna: {uri: 'https://imgur.com/aFtcYhE.png'},
  contactaLaia: {uri: 'https://imgur.com/K5UpQBF.png'},
  phone: {uri: 'https://imgur.com/F52AdJH.png'},
  quoteAriadna: {uri: 'https://imgur.com/2i3K4He.png'},
  whatsApp: {uri: 'https://imgur.com/6r8FKoc.png'},
  modalImage: {uri: 'https://imgur.com/IJES5T1.png'},
  chevronRight: {uri: 'https://imgur.com/Q2fepA5.png'},
  close: {uri: 'https://imgur.com/NiN2NZL.png'},
  mockupFind: {uri: 'https://imgur.com/ic2WAcw.png'},
  chevronLeft: {uri: 'https://imgur.com/QTl0XrN.png'},
  video: {uri: 'https://imgur.com/zlqgtVm.png'},

  back: require('./assets/Icon/chevron-left.png'),
  bell: require('./assets/Icon/bell.png'),
  chat: require('./assets/Icon/message-square.png'),
  chevronDown: require('./assets/Icon/Path.png'),
  send: require('./assets/Icon/send.png'),
  profile: require('./assets/Icon/user.png'),
  onb1: require('./assets/onboarding/1.png'),
  onb2: require('./assets/onboarding/2.png'),
};
